<template>
  <div>
    <h1>Terms And Conditions</h1>
    <p>
      <strong>Terms of Use for MurshiSoft LLP Products and Services</strong>
    </p>
    <p>
      The following terms and conditions (“Terms and Conditions”) govern your
      use of this, the web service murshisoft.com or any other web service or
      site owned and/or operated by MurshiSoft (the “Service”), and by
      accessing, viewing, or using the material and functionality on the
      Service, you indicate that you understand and intend these Terms and
      Conditions to be the legal equivalent of a signed, written contract, and
      equally binding, and that you accept such Terms and Conditions and agree
      to be legally bound by them. If you do not agree with the Terms and
      Conditions, you are not granted permission to use the Service and should
      exit immediately.
    </p>
    <p>
      <strong>Proprietary Rights.</strong> All material contained in this
      Service is protected by law, including, but not limited to, Indian
      copyright law. Except as indicated, the entire content (including images,
      text, and look and feel attributes) of murshisoft.com is (C) 2020
      MurshiSoft All rights reserved. Removing or altering the copyright notice
      on any material on the Service is prohibited. MurshiSoft also owns a
      copyright in this Service as a collective work and/or compilation, and in
      the selection, coordination, arrangement, organization, and enhancement of
      such content. Any commercial use of this content is prohibited without the
      prior written consent of MurshiSoft. All trademarks used at this web-site
      that are not the intellectual property of MurshiSoft are property of their
      respective holders.
    </p>
    <p>
      <strong>Communications With Our Web Service.</strong> MurshiSoft welcomes
      your feedback and suggestions about how to improve our products and
      services and this Service. By transmitting any suggestions, information,
      material, or other content (collectively, “content”) to MurshiSoft LLP,
      you automatically grant MurshiSoft the royalty-free, perpetual,
      irrevocable, non-exclusive right and license to use, reproduce, modify,
      adapt, publish, translate, create derivative works from, distribute,
      redistribute, transmit, perform, and display such content (in whole or
      part) worldwide and/or to incorporate it in other works in any form,
      media, or technology now known or later developed for the full term of any
      rights that may exist in such content. Further, MurshiSoft LLP is free to
      use any ideas, concepts, know-how, techniques, and suggestions contained
      in any communications you send to this Service for any purpose whatever,
      including but not limited to creating and marketing products and/or
      services using such information.
    </p>
    <p>
      <strong>No Solicitation or Offer or Advice.</strong> This Service is
      designed to provide general information about MurshiSoft and its products
      and services. Information on the Service is not intended to constitute an
      offer to sell or a solicitation of any particular product or service.
    </p>
    <p>
      <strong>Privacy Statement.</strong> MurshiSoft has a Privacy Statement
      disclosing what information we collect about visitors, how we use such
      information and the steps we take to secure such information.
      <a href="https://murshisoft.com/privacy-statement/">Click here</a>
      to view the Privacy Statement, which is incorporated by reference, as if
      set forth fully herein.
    </p>
    <p>
      <strong>Disclaimer of Warranty and Liability.</strong> Use of this service
      is entirely at your own risk. Neither MurshiSoft nor its affiliates is
      responsible for the consequences of reliance on any information contained
      in or submitted to the service, and the risk of injury from the foregoing
      rests entirely with you. These materials are provided “as is” without
      warranty of any kind, either express or implied. MurshiSoft shall not be
      liable for any direct, special, indirect, incidental, consequential, or
      punitive damages, including without limitation, lost revenues or lost
      profits, which may result from the use of, access to, or inability to use
      these materials. Under no circumstances will the total liability of
      MurshiSoft to you exceed the price paid for use of the service.
    </p>
    <p>
      <strong>Corrections and Changes.</strong> While we endeavor to keep these
      materials up to date, MurshiSoft cannot assume responsibility for any
      errors or omissions in these materials. MurshiSoft further does not
      warrant the accuracy or completeness of the information, text, graphics,
      links or other items contained within these materials. MurshiSoft may make
      changes to these materials, or to the products or services described
      herein, at any time without notice, and makes no commitment to update the
      information contained herein. MurshiSoft LLP reserves the right to
      terminate your access to the Service in the event that you violate these
      Terms and Conditions, or for any reason whatever.
    </p>
    <p>
      <strong>Indemnification.</strong> You agree to defend, indemnify, and hold
      harmless MurshiSoft, its affiliates and subsidiaries, and all of their
      respective directors, officers, employees, representatives, proprietors,
      partners, shareholders, servants, principals, agents, predecessors,
      successors, assigns, and attorneys from and against any and all claims,
      proceedings, damages, injuries, liabilities, losses, costs, and expenses
      (including attorney’s fees and litigation expenses) relating to or arising
      from your use of the Service and any breach by you of these Terms and
      Conditions.
    </p>
    <p>
      <strong>Links to Other Web Services.</strong> This Service may, from time
      to time, contain links to other Internet web services for the convenience
      of users in locating one of our clients’ services, information, or
      services that may be of interest. These services are maintained by
      organizations over which MurshiSoft exercises no control, and MurshiSoft
      expressly disclaims any responsibility for the content, the accuracy of
      the information and/or quality of products or services provided by or
      advertised on these third-party services. MurshiSoft LLP does not control,
      endorse, promote, or have any affiliation with any other web service
      unless expressly stated herein.
    </p>
    <p>
      <strong>Use of the Internet.</strong> Use of the Internet is solely at
      your own risk and is subject to all applicable provincial, national and
      international laws and regulations. Neither MurshiSoft nor its affiliates
      will be liable for any loss resulting from a cause over which they do not
      have direct control, including but not limited to failure of electronic or
      mechanical equipment or communication lines, telephone or other
      interconnection problems, computer viruses, unauthorized access, theft,
      operator errors, severe weather, earthquakes, natural disasters, strikes
      or other labor problems, wars, or governmental restrictions.
    </p>
    <p>
      <strong>Governing Law and Jurisdiction.</strong> These Terms and
      Conditions will be governed by and construed in accordance with the laws
      of the State of Kerala, India, without reference to its choice of law
      rules. By accessing, viewing, or using the material on the Service, you
      consent to the jurisdiction of the federal and provincial courts presiding
      in Toronto, Ontario, and agree to accept service of process by mail and
      hereby waive any and all jurisdictional and venue defenses otherwise
      available. This Service is controlled and operated by MurshiSoft from its
      offices within India. MurshiSoft makes no representation that materials in
      the Service are appropriate or available for use in other locations, and
      access to them from territories where their contents are illegal is
      prohibited. Those who choose to access this Service from other locations
      do so of their own volition and are responsible for compliance with
      applicable local laws.
    </p>
    <p>
      These Terms and Conditions constitute the entire agreement between you and
      MurshiSoft with respect to your use of the Service. You acknowledge that,
      in providing you access to and use of the Service, MurshiSoft LLP has
      relied on your agreement to be legally bound by these Terms and
      Conditions.
    </p>
    <p><strong>Contact Information</strong></p>
    <p>
      MurshiSoft welcomes your comments regarding this Terms of Use, please
      contact us by e-mail, or postal mail.
    </p>
    <p>
      <strong>Terms of Use for MurshiSoft LLP Products and Services</strong>
    </p>
    <p>
      The following terms and conditions (“Terms and Conditions”) govern your
      use of this, the web service murshisoft.com or site owned and/or operated
      by MurshiSoft LLP (the “Service”), and by accessing, viewing, or using the
      material and functionality on the Service, you indicate that you
      understand and intend these Terms and Conditions to be the legal
      equivalent of a signed, written contract, and equally binding, and that
      you accept such Terms and Conditions and agree to be legally bound by
      them. If you do not agree with the Terms and Conditions, you are not
      granted permission to use the Service and should exit immediately.
    </p>
    <p>
      <strong>Proprietary Rights.</strong> All material contained in this
      Service is protected by law, including, but not limited to, Indian
      copyright law. Except as indicated, the entire content (including images,
      text, and look and feel attributes) of murshisoft.com (C) 2020 MurshiSoft
      LLP All rights reserved. Removing or altering the copyright notice on any
      material on the Service is prohibited. MurshiSoft LLP also owns a
      copyright in this Service as a collective work and/or compilation, and in
      the selection, coordination, arrangement, organization, and enhancement of
      such content. Any commercial use of this content is prohibited without the
      prior written consent of MurshiSoft LLP. All trademarks used at this
      web-site that are not the intellectual property of MurshiSoft LLP are
      property of their respective holders.
    </p>
    <p>
      <strong>Communications With Our Web Service.</strong> MurshiSoft LLP
      welcomes your feedback and suggestions about how to improve our products
      and services and this Service. By transmitting any suggestions,
      information, material, or other content (collectively, “content”) to
      MurshiSoft LLP, you automatically grant MurshiSoft LLP the royalty-free,
      perpetual, irrevocable, non-exclusive right and license to use, reproduce,
      modify, adapt, publish, translate, create derivative works from,
      distribute, redistribute, transmit, perform, and display such content (in
      whole or part) worldwide and/or to incorporate it in other works in any
      form, media, or technology now known or later developed for the full term
      of any rights that may exist in such content. Further, MurshiSoft LLP is
      free to use any ideas, concepts, know-how, techniques, and suggestions
      contained in any communications you send to this Service for any purpose
      whatever, including but not limited to creating and marketing products
      and/or services using such information.
    </p>
    <p>
      <strong>No Solicitation or Offer or Advice.</strong> This Service is
      designed to provide general information about MurshiSoft LLP and its
      products and services. Information on the Service is not intended to
      constitute an offer to sell or a solicitation of any particular product or
      service.
    </p>
    <p>
      <strong>Privacy Statement.</strong> MurshiSoft LLP has a Privacy Statement
      disclosing what information we collect about visitors, how we use such
      information and the steps we take to secure such information.
      <a href="https://www.murshisoft.com/privacy-statement/">Click here</a>
      to view the Privacy Statement, which is incorporated by reference, as if
      set forth fully herein.
    </p>
    <p>
      <strong>Disclaimer of Warranty and Liability.</strong> Use of this service
      is entirely at your own risk. Neither MurshiSoft LLP nor its affiliates is
      responsible for the consequences of reliance on any information contained
      in or submitted to the service, and the risk of injury from the foregoing
      rests entirely with you. These materials are provided “as is” without
      warranty of any kind, either express or implied. MurshiSoft LLP shall not
      be liable for any direct, special, indirect, incidental, consequential, or
      punitive damages, including without limitation, lost revenues or lost
      profits, which may result from the use of, access to, or inability to use
      these materials. Under no circumstances will the total liability of
      MurshiSoft LLP to you exceed the price paid for use of the service.
    </p>
    <p>
      <strong>Corrections and Changes.</strong> While we endeavor to keep these
      materials up to date, MurshiSoft LLP cannot assume responsibility for any
      errors or omissions in these materials. MurshiSoft LLP further does not
      warrant the accuracy or completeness of the information, text, graphics,
      links or other items contained within these materials. MurshiSoft LLP may
      make changes to these materials, or to the products or services described
      herein, at any time without notice, and makes no commitment to update the
      information contained herein. MurshiSoft LLP reserves the right to
      terminate your access to the Service in the event that you violate these
      Terms and Conditions, or for any reason whatever.
    </p>
    <p>
      <strong>Indemnification.</strong> You agree to defend, indemnify, and hold
      harmless MurshiSoft LLP, its affiliates and subsidiaries, and all of their
      respective directors, officers, employees, representatives, proprietors,
      partners, shareholders, servants, principals, agents, predecessors,
      successors, assigns, and attorneys from and against any and all claims,
      proceedings, damages, injuries, liabilities, losses, costs, and expenses
      (including attorney’s fees and litigation expenses) relating to or arising
      from your use of the Service and any breach by you of these Terms and
      Conditions.
    </p>
    <p>
      <strong>Links to Other Web Services.</strong> This Service may, from time
      to time, contain links to other Internet web services for the convenience
      of users in locating one of our clients’ services, information, or
      services that may be of interest. These services are maintained by
      organizations over which MurshiSoft LLP exercises no control, and
      MurshiSoft LLP expressly disclaims any responsibility for the content, the
      accuracy of the information and/or quality of products or services
      provided by or advertised on these third-party services. MurshiSoft LLP
      does not control, endorse, promote, or have any affiliation with any other
      web service unless expressly stated herein.
    </p>
    <p>
      <strong>Use of the Internet.</strong> Use of the Internet is solely at
      your own risk and is subject to all applicable provincial, national and
      international laws and regulations. Neither MurshiSoft LLP nor its
      affiliates will be liable for any loss resulting from a cause over which
      they do not have direct control, including but not limited to failure of
      electronic or mechanical equipment or communication lines, telephone or
      other interconnection problems, computer viruses, unauthorized access,
      theft, operator errors, severe weather, earthquakes, natural disasters,
      strikes or other labor problems, wars, or governmental restrictions.
    </p>
    <p>
      <strong>Governing Law and Jurisdiction.</strong> These Terms and
      Conditions will be governed by and construed in accordance with the laws
      of the State of Kerala, India, without reference to its choice of law
      rules. By accessing, viewing, or using the material on the Service, you
      consent to the jurisdiction of the federal and provincial courts presiding
      in Toronto, Ontario, and agree to accept service of process by mail and
      hereby waive any and all jurisdictional and venue defenses otherwise
      available. This Service is controlled and operated by MurshiSoft LLP from
      its offices within India. MurshiSoft LLP makes no representation that
      materials in the Service are appropriate or available for use in other
      locations, and access to them from territories where their contents are
      illegal is prohibited. Those who choose to access this Service from other
      locations do so of their own volition and are responsible for compliance
      with applicable local laws.
    </p>
    <p>
      These Terms and Conditions constitute the entire agreement between you and
      MurshiSoft LLP with respect to your use of the Service. You acknowledge
      that, in providing you access to and use of the Service, MurshiSoft LLP
      has relied on your agreement to be legally bound by these Terms and
      Conditions.
    </p>
    <p><strong>Contact Information</strong></p>
    <p>
      MurshiSoft LLP welcomes your comments regarding this Terms of Use, please
      contact us by e-mail, or postal mail.
    </p>
    <dl>
      <dt>&nbsp;</dt>
      <dd>MurshiSoft LLP</dd>
      <dt>&nbsp;</dt>
      <dd>VII/42 Paloth Poovathikkal</dd>
      <dt>&nbsp;</dt>
      <dd>Malappuram, Kerala</dd>
      <dt>&nbsp;</dt>
      <dd>673639 India</dd>
    </dl>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
